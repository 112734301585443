import * as React from 'react';

class Patient extends React.Component<{}> {
    public render() {
        return (
            <React.Fragment>
                <p>Patient Report Page is under construction.</p>
            </React.Fragment>
        );
    }
}

export default Patient;
