import * as React from 'react';

class Api extends React.Component<{}> {
    public render() {
        return (
            <React.Fragment>
                <p>API Usage Page is under construction.</p>
            </React.Fragment>
        );
    }
}

export default Api;
